<template>
  <svg :width="width" :height="height" xmlns="http://www.w3.org/2000/svg" viewBox="-400 -400 1800 1800">
    <g>
      <circle cx="500" cy="500" r="800" fill="none" stroke-width="80" :stroke="color" />
      <path :fill="color" d="M872,468.6c-4.6-101-45.1-201.7-123.6-278.9C669.6,111.5,566.6,71.8,463.7,67.4L458,10c119.2,7.1,235.3,53.4,326.6,143.1c91.5,89.9,139.3,203.5,146.4,320.8L872,467.6z M794.4,480l-56.9-7.9c0.9-70.1-25.3-141-79.7-194.5c-53.4-52.4-123.9-78.2-194-78.3L453.5,143c86.7,2.1,173.4,35.1,239.5,100C760.4,309.2,793.4,393.3,794.4,480z M657.1,476.6l-50.5-14c5.9-35.7-13.1-67-41.2-94.6c-25.5-25-59.8-36.3-93.1-33.8l-19.5-56c53.3-3.4,107.7,14.8,148.4,54.8C641.4,372.5,660,425.1,657.1,476.6L657.1,476.6z M550.1,445.6c0,36.3-30,65.8-67,65.8c-12.8,0-24.6-3.7-34.8-9.8L419.5,530L628,732.4c-5.1,6-10.5,12.1-16.6,18.1c-116.9,114.7-306.4,114.7-423.4,0C71.1,635.7,71.1,449.7,188,335c5.3-5.3,10.7-9.7,16-14.2l196.4,190.7l28.2-27.7c-7.9-10.8-12.6-23.9-12.5-38.1c0-36.3,30-65.8,67.1-65.8C520.1,379.9,550.1,409.3,550.1,445.6z M294.3,838.2c-2.5,0,68.1,151.8,68.1,151.8H68.9l101.2-234.5C170.2,755.4,200.9,800.3,294.3,838.2L294.3,838.2z"/>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconBroadcast = Vue.extend({
  name: 'icon-broadcast',
  props: {
    color: {
      type: String,
      default: '#000',
    },
    width: {
      type: Number,
      default: 22,
    },
    height: {
      type: Number,
      default: 22,
    },
  },
});
export default IconBroadcast;
</script>
