


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import StreamPlayer from '@/_components/stream-player/stream-player.vue';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TMediaItem } from '@/_modules/events/types/media-item.type';

@Component({
  name: 'company-media-block',
  components: {
    StreamPlayer,
  },
  computed: {
    ...mapGetters({
      promoPageByExternalId: 'promoStore/promoPageByExternalId',
      unpinnedMediaItems: 'promoStore/unpinnedMediaItems',
    }),
  }
})
export default class CompanyMediaBlock extends Vue {

  @Prop({ type: String })
  public readonly externalId: string;

  public readonly promoPageByExternalId: (externalId: string) => TPromoPage;

  public get promoPage(): TPromoPage {
    return this.promoPageByExternalId(this.externalId);
  }

  public get companyMediaItems(): TMediaItem[] {
    if (!this.promoPage || !this.promoPage.companyMediaItems) {
      return [];
    }
    return this.promoPage.companyMediaItems;
  }

  public activeSlideIndex: number = 0;
  public mediaItems: TMediaItem[] = [];

  public mounted(): void {
    this.onCarouselItemChange(this.activeSlideIndex);
  }

  public onCarouselItemChange(index: number): void {
    this.activeSlideIndex = index;

    this.stopAllVideos();

    this.$nextTick(() => {
      if (!this.$refs.carouselItems) {
        return;
      }
      const activeCarouselItem: any = (this.$refs.carouselItems as any[]).find((component: any) => {
        return !!component.active;
      });
      if (!activeCarouselItem) {
        return;
      }
      const activeCarouselItemId = activeCarouselItem.$el.getAttribute('id');
      const activeMediaItem = this.companyMediaItems.find((mediaItem: TMediaItem) => {
        return mediaItem.id === activeCarouselItemId;
      });

      if (activeMediaItem) {
        this.$store.dispatch('promoStore/setActiveCompanyMediaItem', {
          externalId: this.externalId,
          id: activeMediaItem.id,
        });
      }
    });
  }

  public toggleVideoPlay(event: MouseEvent): void {
    let vidEl = event.target as HTMLVideoElement;
    if (vidEl.tagName.toLowerCase() !== 'video') {
      vidEl = vidEl.childNodes[0] as HTMLVideoElement;
    }
    try {
      if (vidEl.paused) {
        vidEl.play();
      } else {
        vidEl.pause();
      }
    } catch (error) {
      // ?
    }
  }

  public onVideoPlayPauseChange(item: TMediaItem, isPaused: boolean): void {
    this.$store.dispatch('promoStore/setCompanyMediaItemIsPaused', {
      externalId: this.externalId,
      id: item.id,
      isPaused: isPaused,
    });
  }

  @Watch('unpinnedMediaItems', { immediate: false })
  private onUnpinnedMediaItemsChange(): void {
    this.onCarouselItemChange(this.activeSlideIndex);
  }

  @Watch('companyMediaItems', { immediate: true, deep: true })
  private onCompanyMediaItemsChange(): void {

    if (!this.promoPage.companyMediaItems) {
      this.mediaItems = [];
    } else {
      this.mediaItems = [ ...this.promoPage.companyMediaItems.filter((mediaItem: TMediaItem) => {
        return !mediaItem.isUnpinned;
      }) ];
    }

    if (this.activeSlideIndex > this.mediaItems.length - 1) {
      this.activeSlideIndex = Math.max(0, this.mediaItems.length - 1);
    }
  }

  // @Watch('companyMediaItems', { immediate: true })
  // private onCompanyMediaItemsChange(): void {
  //   this.updateMediaItems();
  // }

  private stopAllVideos(): void {
    const allVideos = this.$refs.videoSlideContents as Element[];
    if (!allVideos) { // AW-413
      return;
    }
    for (const video of allVideos) {
      (video as HTMLVideoElement).pause();
    }
    this.$store.dispatch('promoStore/setAllCompanyVideoMediaItemsIsPaused', {
      externalId: this.externalId,
    });
  }
}

