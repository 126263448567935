

import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ItemsMenu, { TItemsMenuItem } from '@/_modules/controls/components/items-menu/items-menu.vue';
import { TContact } from '@/_types/contact.type';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import { TEvent } from '@/_types/event.type';
import EventHelper from '@/_helpers/event.helper';
import { TimeStatus } from '@/_types/time-status.enum';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';

enum PromoBroadcastMenuItem {
  WEB = 'web',
  ZOOM = 'zoom',
  OBS = 'obs',
  CODE = 'code',
}

@Component({
  name: 'promo-broadcast-menu',
  components: {
    ItemsMenu,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      contact: 'promoPageStore/contact',
    }),
  },
})
export default class PromoBroadcastMenu extends Vue {

  @Prop({ type: String })
  public readonly type: BroadcastType;

  @Prop({ type: Number })
  public readonly programId: number;

  public items: TItemsMenuItem[] = [
    {
      value: PromoBroadcastMenuItem.WEB,
      label: this.$t('promo.company.card.broadcastMenu.labelWeb'),
    },
    {
      value: PromoBroadcastMenuItem.ZOOM,
      label: this.$t('promo.company.card.broadcastMenu.labelZoom'),
    },
    {
      value: PromoBroadcastMenuItem.OBS,
      label: this.$t('promo.company.card.broadcastMenu.labelOBS'),
    },
    {
      value: PromoBroadcastMenuItem.CODE,
      label: this.$t('promo.company.card.broadcastMenu.labelCode'),
    },
  ];

  public readonly event: TEvent;
  public readonly contact: TContact;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get meetingRoomConfig(): TMeetingRoomConfig {
    if (!this.eventId || !this.contact || !this.contact.id) {
      return null;
    }

    switch (this.type) {
      case BroadcastType.PROMO_CONTACT:
        return {
          type: MeetingRoomType.BROADCAST,
          broadcastType: this.type,
          eventId: this.eventId,
          contactId: this.contact.id,
        };

      case BroadcastType.PROGRAM_SPEAKER:
        return {
          type: MeetingRoomType.BROADCAST,
          broadcastType: this.type,
          eventId: this.eventId,
          contactId: this.contact.id,
          programId: this.programId,
        };
    }

    return null;
  }

  public onItemChoose(value: PromoBroadcastMenuItem): void {

    const meetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }

    switch (value) {
      case PromoBroadcastMenuItem.WEB: {
        const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
        if (eventTimeStatus === TimeStatus.PAST) {
          this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
          return;
        }
        if (meetingRoomConfig) {
          this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
        }
        break;
      }

      case PromoBroadcastMenuItem.OBS: {
        const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
        if (eventTimeStatus === TimeStatus.PAST) {
          this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
          return;
        }
        this.$store.dispatch('_eventStore/setObsSettingsDialogConfig', meetingRoomConfig);
        break;
      }

      case PromoBroadcastMenuItem.ZOOM: {
        const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
        if (eventTimeStatus === TimeStatus.PAST) {
          this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
          return;
        }
        this.$store.dispatch('_eventStore/setZoomSettingsDialogConfig', meetingRoomConfig);
        break;
      }

      case PromoBroadcastMenuItem.CODE:
        this.$store.dispatch('_eventStore/setEmbedCodeDialogConfig', meetingRoomConfig);
        break;
    }
  }

}
