<template>
  <svg width="22" height="22" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g :style="style">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M31 15.5C31 6.93958 24.0604 0 15.5 0C6.93958 0 0 6.93958 0 15.5C0 24.0604 6.93958 31 15.5 31C24.0604 31 31 24.0604 31 15.5ZM9.73506 1.85254C11.5606 1.0804 13.5002 0.688889 15.5 0.688889C17.4998 0.688889 19.4394 1.0804 21.2649 1.85254C23.0286 2.59848 24.6126 3.66652 25.973 5.02696C27.3335 6.38741 28.4015 7.97144 29.1475 9.73506C29.9196 11.5606 30.3111 13.5002 30.3111 15.5C30.3111 17.4998 29.9196 19.4394 29.1475 21.2649C28.4015 23.0286 27.3335 24.6126 25.973 25.973C24.6126 27.3335 23.0286 28.4015 21.2649 29.1475C19.4394 29.9196 17.4998 30.3111 15.5 30.3111C13.5002 30.3111 11.5606 29.9196 9.73506 29.1475C7.97144 28.4015 6.38741 27.3335 5.02696 25.973C3.66652 24.6126 2.59848 23.0286 1.85254 21.2649C1.0804 19.4394 0.688889 17.4998 0.688889 15.5C0.688889 13.5002 1.0804 11.5606 1.85254 9.73506C2.59848 7.97144 3.66652 6.38741 5.02696 5.02696C6.38741 3.66652 7.97144 2.59848 9.73506 1.85254Z"
            fill="#FFA087"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M24.8028 10.9811C24.6805 10.9145 24.5292 10.9119 24.4044 10.9745L20.045 13.1615V11.8492C20.0437 10.8284 19.1433 10.0012 18.0322 10H8.01271C6.90163 10.0012 6.00126 10.8284 6 11.8492V19.1508C6.00126 20.1716 6.90163 20.9988 8.01271 21H18.0322C19.1433 20.9988 20.0437 20.1716 20.045 19.1508V17.8624L24.4045 20.0494C24.5292 20.1119 24.6806 20.1095 24.8028 20.0429C24.9252 19.9761 25 19.8552 25 19.7248V11.2991C25 11.1685 24.925 11.0477 24.8028 10.9811ZM19.2396 19.1509C19.2389 19.7635 18.6986 20.2597 18.0319 20.2605H8.01271C7.346 20.2597 6.80587 19.7635 6.80508 19.1509V11.8492C6.80587 11.2368 7.346 10.7404 8.01271 10.7397H18.0322C18.6988 10.7404 19.2391 11.2368 19.2399 11.8492L19.2396 19.1509ZM20.045 17.0197L24.1949 19.1015V11.9225L20.045 14.0042V17.0197Z"
            fill="#FFA087"/>
    </g>
  </svg>

</template>

<script>
import Vue from 'vue';

const IconVideocam = Vue.extend({
  name: 'icon-videocam',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    style() {
      return `stroke: ${this.color};`;
    },
  },
});
export default IconVideocam;
</script>

<style scoped lang="scss">
  /* stylelint-disable */
  .cls-1,
  .cls-2,
  .cls-4 {
    fill: none;
  }

  .cls-1,
  .cls-2 {
    stroke: #000;
  }

  .cls-2 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .cls-3 {
    stroke: none;
  }
</style>
